/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { io } from "socket.io-client";
import { setMessageData, setOpenMessageModal } from "./messageModal";
import { useDispatch, useSelector } from "react-redux";

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
    const token = useSelector(state => state.user?.token)
    const userData = JSON.parse(localStorage.getItem('admin_data'))
    const [socket, setSocket] = useState(null);
    const dispatch = useDispatch()
    const socketRef = useRef(null);

    useEffect(() => {
        const initializeSocket = async () => {
            try {
                const newSocket = io(process.env.REACT_APP_BASEURL, {
                    reconnectionAttempts: 15,
                    transports: ["websocket"],
                });
                newSocket.emit("authenticate", token);
                newSocket.on("authenticated", (id) => {
                    setSocket(newSocket);
                });
                newSocket.on("connect_error", (error) => {
                    console.error("Socket connection error:", error);
                });

                newSocket.on("unauthorized", (error) => {
                    console.error("Unauthorized socket connection:", error.message);
                });
                newSocket.on("send-message", (data) => {
                    if (userData?._id !== data?.sender?._id) {
                        dispatch(setMessageData({
                            title: 'New Message Received',
                            message: `${data?.sender?.name} sent you a message: ${data?.message} from @${data?.sender?.name}`,
                            type: "message"
                        }))
                        dispatch(setOpenMessageModal(true))
                    }
                });
                newSocket.on("admin-noti", (data) => {
                    dispatch(setMessageData({
                        ...data,
                        // type: "order"
                    }))
                    dispatch(setOpenMessageModal(true))
                });
                newSocket.on("disconnect", () => {
                    setSocket(null);
                    console.log('disconect');

                    initializeSocket();
                });
                socketRef.current = newSocket;
            } catch (error) {
                console.error("Error initializing socket:", error);
            }
        };
        if (token) {
            initializeSocket();
        } else {
        }
        return () => {
            if (!token && socketRef.current) {
                socketRef.current.disconnect();
                setSocket(null);
            }
        };
    }, [token]);

    return (
        <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
    );
};