import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { binStatus } from './binStatus';
import { messageModal } from './messageModal';
import { user } from './user';

// Define the root reducer
const rootReducer = combineReducers({
  binStatus: binStatus.reducer,
  messageModal: messageModal.reducer,
  user: user.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['binStatus', 'user'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false
    });
  }
});

// Persist the store
persistStore(store);

// Export the store
export { store };

