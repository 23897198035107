import { createSlice } from '@reduxjs/toolkit';

export const messageModal = createSlice({
  name: 'messageModal',
  initialState: {
    messageData: null,
    openMessageModal: false,
  },
  reducers: {
    setMessageData: (state, action) => {
      state.messageData = action.payload;
    },
    setOpenMessageModal: (state, action) => {
      state.openMessageModal = action.payload;
    },
  },
});

export const { setMessageData, openMessageModal, setOpenMessageModal, messageData } = messageModal.actions;

export default messageModal.reducer